import React, { Fragment, FunctionComponent } from 'react'
import { useAdTypeCounters } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/start-pages/SearchEntryArea/useAdTypeCounters'
import TabIconCar from '@wh-components/icons/AmCar'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import TabIconMotorbike from '@wh-components/icons/AmMotorbike'
import TabIconTruck from '@wh-components/icons/AmTruck'
import TabIconCaravan from '@wh-components/icons/AmCaravan'
import {
    AdTypeIcon,
    HyphenatedTitle,
    Tab,
} from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchEntryAreaTabs'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AutoMotorAdTypeCounters } from '@bbx/search-journey/common/api/searchApiClient'

export interface AutoMotorSearchEntryAreaTabsProps {
    activeTab: 'car' | 'motorcycle'
    taggingData: TaggingData
    initialCounters: AutoMotorAdTypeCounters
}

export const AutoMotorSearchEntryAreaTabs: FunctionComponent<AutoMotorSearchEntryAreaTabsProps> = (props) => {
    const counters = useAdTypeCounters(props.initialCounters)

    return (
        <Fragment>
            <Tab
                active={props.activeTab === 'car'}
                Icon={<AdTypeIcon Icon={TabIconCar} active={props.activeTab === 'car'} />}
                title={<HyphenatedTitle>Gebraucht&shy;wagen</HyphenatedTitle>}
                counter={counters.cars}
                href={staticRelativeCanonicals.MotorCarHome}
                onClick={() => callActionEvent('vertical_home_tab_car_click', props.taggingData)}
                testIdPrefix="car"
            />
            <Tab
                active={props.activeTab === 'motorcycle'}
                Icon={<AdTypeIcon Icon={TabIconMotorbike} active={props.activeTab === 'motorcycle'} />}
                title="Motorrad & Quad"
                counter={counters.motorbikes}
                href={staticRelativeCanonicals.MotorMcHome}
                onClick={() => callActionEvent('vertical_home_tab_mc_click', props.taggingData)}
                testIdPrefix="mc"
            />
            <Tab
                Icon={<AdTypeIcon Icon={TabIconTruck} />}
                title="Nutzfahrzeug & Pickup"
                counter={counters.trucks}
                href={staticRelativeCanonicals.MotorTruckResultList}
                onClick={() => callActionEvent('vertical_home_tab_truck_click', props.taggingData)}
                testIdPrefix="truck"
            />
            <Tab
                Icon={<AdTypeIcon Icon={TabIconCaravan} />}
                title="Wohnwagen & Wohnmobil"
                counter={counters.caravans}
                href={staticRelativeCanonicals.MotorCaravanResultList}
                onClick={() => callActionEvent('vertical_home_tab_caravan_click', props.taggingData)}
                testIdPrefix="caravan"
            />
        </Fragment>
    )
}
