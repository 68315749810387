import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { Navigator } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '../../../../common/common/SearchCallback'
import { areSetsEqual } from '@wh/common/chapter/lib/functionalHelpers'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Text } from '@wh-components/core/Text/Text'

type MotorConditionCheckboxNavigatorProps = {
    type: 'car' | 'motorcycle'
    motorconditionNavigator: Navigator
    triggerSearch: SearchCallback
    disabled: boolean
    taggingData: TaggingData
} & Pick<FlexItemProps, 'flex'>

enum CAR_MOTOR_CONDITION_IDS {
    Gebrauchtwagen = '20',
    Jahreswagen = '50',
    Neuwagen = '10',
    Oldtimer = '93',
    Tageszulassung = '91',
    Unfallwagen = '30',
    Vorfuehrwagen = '40',
}

enum MC_MOTOR_CONDITION_IDS {
    Gebrauchtfahrzeug = '70',
    Jahresfahrzeug = '92',
    Neufahrzeug = '60',
    Oldtimer = '93',
    Tageszulassung = '91',
    Unfallfahrzeug = '80',
    Vorfuehrfahrzeug = '90',
}

const newCarMotorConditions = [
    CAR_MOTOR_CONDITION_IDS.Neuwagen,
    CAR_MOTOR_CONDITION_IDS.Jahreswagen,
    CAR_MOTOR_CONDITION_IDS.Tageszulassung,
    CAR_MOTOR_CONDITION_IDS.Vorfuehrwagen,
]

const newMcMotorConditions = [
    MC_MOTOR_CONDITION_IDS.Neufahrzeug,
    MC_MOTOR_CONDITION_IDS.Jahresfahrzeug,
    MC_MOTOR_CONDITION_IDS.Tageszulassung,
    MC_MOTOR_CONDITION_IDS.Vorfuehrfahrzeug,
]

const newMotorConditionsForType = (type: 'car' | 'motorcycle') =>
    ({
        car: newCarMotorConditions,
        motorcycle: newMcMotorConditions,
    })[type]

const areNewMotorConditionsSelected = (type: 'car' | 'motorcycle', navigator: Navigator) => {
    const expectedMotorConditions = new Set([...newMotorConditionsForType(type)])
    const selectedMotorConditions = new Set(navigator.selectedValues.map((s) => s.urlParamRepresentationForValue[0].value))
    return areSetsEqual(selectedMotorConditions, expectedMotorConditions)
}

export const MotorConditionCheckboxNavigator: FunctionComponent<MotorConditionCheckboxNavigatorProps> = (props) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const searchBoxForegroundTextColor = advertisingState.pageModifications.foregroundColors?.['motor-search-box-text']

    const [checkboxState, setCheckboxState] = useState<boolean>(areNewMotorConditionsSelected(props.type, props.motorconditionNavigator))
    useEffect(() => {
        setCheckboxState(areNewMotorConditionsSelected(props.type, props.motorconditionNavigator))
    }, [props.type, props.motorconditionNavigator])

    const label = { car: 'nur Neu- und Jungwagen', motorcycle: 'nur Neu- und Jungfahrzeuge' }[props.type]

    return (
        <Checkbox
            flex={props.flex}
            label={<Text color={searchBoxForegroundTextColor}>{label}</Text>}
            name="checkbox-new"
            id="checkbox-new"
            checked={checkboxState}
            testId="checkbox-new"
            disabled={props.disabled}
            onChange={(e) => {
                setCheckboxState(e.target.checked)

                callActionEvent(
                    e.target.checked
                        ? 'vertical_home_search_box_condition_new_activate'
                        : 'vertical_home_search_box_condition_new_deactivate',
                    props.taggingData,
                )

                if (props.motorconditionNavigator) {
                    if (e.target.checked) {
                        const searchParameters = newMotorConditionsForType(props.type)
                        props.triggerSearch(props.motorconditionNavigator.urlConstructionInformation.baseUrl, {
                            [props.motorconditionNavigator.urlConstructionInformation.urlParams[0].urlParameterName]: searchParameters,
                        })
                    } else {
                        if (props.motorconditionNavigator.resetAllInformation) {
                            props.triggerSearch(props.motorconditionNavigator.resetAllInformation.resetAllUrl)
                        }
                    }
                }
            }}
        />
    )
}
