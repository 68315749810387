import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'

interface ContentAreaProps {
    topContent: ReactNode
    bottomContent?: ReactNode
}

export const ContentArea: FunctionComponent<ContentAreaProps> = ({ topContent, bottomContent }) => (
    <Box backgroundColor="palette.white">
        <Box paddingHorizontal="m" paddingBottom={bottomContent ? 0 : 'm'}>
            {topContent}
        </Box>
        {bottomContent && <Box paddingBottom="m">{bottomContent}</Box>}
    </Box>
)
