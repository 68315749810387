// can be assigned to ResponsiveValue from styled-system
// the difference is that this type makes it mandatory to set the phone value, which allows responsiveValueForBreakpoint to never return undefined
import { ResponsiveBreakpoints } from '@wh-components/core/theme/breakpoints'

export type ResponsiveGuaranteedValue<Value> = Value | { phone: Value; tablet?: Value; desktop?: Value; print?: Value }

export const responsiveValueForBreakpoint = <T extends number | string | boolean>(
    responsiveValue: ResponsiveGuaranteedValue<T>,
    breakpoint: ResponsiveBreakpoints,
): T => {
    if (typeof responsiveValue !== 'object') {
        return responsiveValue
    }

    switch (breakpoint) {
        case 'phone':
            return responsiveValue.phone
        case 'tablet':
            if (typeof responsiveValue.tablet !== 'undefined') {
                return responsiveValue.tablet
            } else {
                return responsiveValue.phone
            }
        case 'desktop':
            if (typeof responsiveValue.desktop !== 'undefined') {
                return responsiveValue.desktop
            } else if (typeof responsiveValue.tablet !== 'undefined') {
                return responsiveValue.tablet
            } else {
                return responsiveValue.phone
            }
        case 'print':
            if (responsiveValue.print) {
                return responsiveValue.print
            } else {
                if (typeof responsiveValue.desktop !== 'undefined') {
                    return responsiveValue.desktop
                } else if (typeof responsiveValue.tablet !== 'undefined') {
                    return responsiveValue.tablet
                } else {
                    return responsiveValue.phone
                }
            }
    }
}
