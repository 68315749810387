import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { AzaWidget } from '../../../../common/start-pages/ContentArea/AzaWidget'
import { SparePartsWidget } from './SparePartsWidget'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface AzaSparePartsRowProps {
    azaUrl: string
    taggingData: TaggingData
}

export const AzaSparePartsRow: FunctionComponent<AzaSparePartsRowProps> = ({ azaUrl, taggingData }) => (
    <Box display="flex" flexDirection={{ phone: 'column-reverse', tablet: 'row' }} marginBottom={{ phone: 'm', tablet: 'l' }}>
        <AzaWidget
            heading="Du willst dein Auto oder Motorrad verkaufen?"
            text="Jetzt gratis Anzeige aufgeben und schnell und einfach verkaufen!"
            azaUrl={azaUrl}
            taggingAction="vertical_home_ad_insertion_button_click"
            taggingData={taggingData}
        />
        <Box width={{ tablet: 300 }} flexShrink={0} marginLeft={{ tablet: 'm' }} marginBottom={{ phone: 'm', tablet: 0 }}>
            <SparePartsWidget taggingData={taggingData} />
        </Box>
    </Box>
)
