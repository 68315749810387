import React, { Fragment, FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import styled, { css } from 'styled-components'
import { ResponsiveGuaranteedValue } from '@wh-components/system'
import { responsiveValueForBreakpoint } from '@wh/common/chapter/lib/responsiveValue'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { ISbStoryData } from '@storyblok/react'
import { StoryblokAutoMotorStartpage, StoryblokMakeLogo } from '@bbx/common/types/storyblok/StoryblokStartpage'
import { InternalRichText } from '@wh/common/cms/components/bloks/InternalRichText'
import { SbBlokData } from '@storyblok/js/dist/types/types'
import { storyblokResolveSearchLink } from '@wh/common/cms/lib/storyblokResolveSearchLink'

interface MakeVomitWidgetProps {
    taggingData: TaggingData
    story: ISbStoryData<StoryblokAutoMotorStartpage> | null
    taggingPrefix: string
    testId: string
}
export const MakeVomitWidget: FunctionComponent<MakeVomitWidgetProps> = ({ story, taggingData, taggingPrefix, testId }) => {
    const borderWidth = 1
    if (!story?.content.Makes) {
        return null
    }

    return (
        <Box testId={`make-vomit-${testId}`}>
            {story?.content.Makes.map((entry) => {
                return (
                    <Fragment key={entry._uid}>
                        <InternalRichText blok={entry as unknown as SbBlokData} richTextKey="heading" story={story} />

                        <Box paddingRight={`${borderWidth}px`} paddingBottom={`${borderWidth}px`} marginTop="sm" marginBottom="l">
                            <MakeVomitGrid borderWidth={borderWidth} columns={{ phone: 2, tablet: 4, desktop: 8 }}>
                                {entry.items.map((storyBlokMakeEntry, entryIndex) => (
                                    <MakeVomitEntry
                                        entry={storyBlokMakeEntry}
                                        index={entryIndex}
                                        key={entryIndex}
                                        onClickTaggingEvent={() =>
                                            callActionEvent('storyblok_click', taggingData, {
                                                prefix: taggingPrefix,
                                                taggingId: storyBlokMakeEntry.text,
                                            })
                                        }
                                    />
                                ))}
                            </MakeVomitGrid>
                        </Box>
                    </Fragment>
                )
            })}
        </Box>
    )
}

const MakeVomitGrid = styled(Box)<{ borderWidth: number; size?: number; columns?: ResponsiveGuaranteedValue<number> }>`
    display: flex;
    flex-wrap: wrap;

    & > * {
        border: ${(p) => p.borderWidth}px solid ${(p) => p.theme.colors.palette.owl};
        height: ${(p) => p.size ?? 100}px;
        margin-right: -${(p) => p.borderWidth}px;
        margin-bottom: -${(p) => p.borderWidth}px;
        min-width: ${(p) => p.size ?? 100}px;
        width: ${(p) => (p.columns ? `calc(${100 / responsiveValueForBreakpoint(p.columns, 'phone')}% + ${p.borderWidth}px)` : undefined)};

        ${(p) => p.theme.media.tablet} {
            width: ${(p) =>
                p.columns ? `calc(${100 / responsiveValueForBreakpoint(p.columns, 'tablet')}% + ${p.borderWidth}px)` : undefined};
        }

        ${(p) => p.theme.media.desktop} {
            width: ${(p) =>
                p.columns ? `calc(${100 / responsiveValueForBreakpoint(p.columns, 'desktop')}% +  ${p.borderWidth}px)` : undefined};
        }
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(
            ${(p) => (p.columns ? responsiveValueForBreakpoint(p.columns, 'phone') : 'auto-fill')},
            minmax(${(p) => p.size ?? 100}px, auto)
        );

        ${(p) => p.theme.media.tablet} {
            grid-template-columns: repeat(
                ${(p) => (p.columns ? responsiveValueForBreakpoint(p.columns, 'tablet') : 'auto-fill')},
                minmax(${(p) => p.size ?? 100}px, auto)
            );
        }

        ${(p) => p.theme.media.desktop} {
            grid-template-columns: repeat(
                ${(p) => (p.columns ? responsiveValueForBreakpoint(p.columns, 'desktop') : 'auto-fill')},
                minmax(${(p) => p.size ?? 100}px, auto)
            );
        }

        & > * {
            width: auto;
            min-width: auto;
        }
    }
`

const MakeVomitEntry: FunctionComponent<{
    entry: StoryblokMakeLogo
    index: number
    onClickTaggingEvent?: () => Promise<void>
}> = ({ entry, index, onClickTaggingEvent }) => (
    <ClientRoutingAnchorLink
        type="anchor"
        href={storyblokResolveSearchLink(entry.url)}
        onClick={onClickTaggingEvent}
        color="palette.verydarkgrey"
        testId={`make-vomit-entry-${index}`}
        css={css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}
        aria-label={`${entry.text} anzeigen`}
    >
        <LazyPicture
            alt={entry.image.alt}
            imgSrcSet={{ x1: `${entry.image.filename}/m/0x45`, x2: `${entry.image.filename}/m/0x90` }}
            height="45px"
        />
    </ClientRoutingAnchorLink>
)
