import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import CatCartoolsIcon from '@wh-components/icons/CatCartools'
import CatDealerofferIcon from '@wh-components/icons/CatDealeroffer'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface SparePartsWidgetProps {
    taggingData: TaggingData
}

export const SparePartsWidget: FunctionComponent<SparePartsWidgetProps> = ({ taggingData }) => (
    <Box border="owl" borderRadius="m" flexDirection="column">
        <ServerRoutingAnchorLink
            type="anchor"
            href="/iad/kaufen-und-verkaufen/kfz-zubehoer-motorradteile"
            height={48}
            paddingHorizontal="m"
            display="flex"
            alignItems="center"
            color="palette.verydarkgrey"
            underline="none"
            testId="vertical-home-spare-parts-link"
            onClick={() => callActionEvent('vertical_home_spareparts_click', taggingData)}
            css={css`
                &:hover {
                    background-color: ${(p) => p.theme.colors.palette.babyseal};
                }
            `}
        >
            <CatCartoolsIcon color="palette.primary.main" size="medium" marginRight="xs" />
            Ersatzteile & Zubehör
        </ServerRoutingAnchorLink>
        <ServerRoutingAnchorLink
            type="anchor"
            href="/iad/gebrauchtwagen/gebrauchtwagenhaendler"
            height={48}
            paddingHorizontal="m"
            display="flex"
            alignItems="center"
            color="palette.verydarkgrey"
            underline="none"
            testId="vertical-home-vendor-link"
            onClick={() => callActionEvent('vertical_home_dealers_click', taggingData)}
            css={css`
                border-top: ${(p) => p.theme.borders.owl};

                &:hover {
                    background-color: ${(p) => p.theme.colors.palette.babyseal};
                }
            `}
        >
            <CatDealerofferIcon color="palette.primary.main" size="medium" marginRight="xs" />
            Händlerliste
        </ServerRoutingAnchorLink>
    </Box>
)
