import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import styled, { css } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import carTypeSuvInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_suv.png?inline'
import carTypeSuvActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_suv_aktiv.png?inline'
import carTypeSportwagenInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_sportwagen.png?inline'
import carTypeSportwagenActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_sportwagen_aktiv.png?inline'
import carTypeLimousineInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_limousine.png?inline'
import carTypeLimousineActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_limousine_aktiv.png?inline'
import carTypeKombiInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kombi.png?inline'
import carTypeKombiActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kombi_aktiv.png?inline'
import carTypeKleinwagenInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kleinwagen.png?inline'
import carTypeKleinwagenActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kleinwagen_aktiv.png?inline'
import carTypeKleinbusInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kleinbus.png?inline'
import carTypeKleinbusActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_kleinbus_aktiv.png?inline'
import carTypeCabrioInactive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_cabrio.png?inline'
import carTypeCabrioActive from '@bbx/static_hashed/img/motor/car_types/automotor_fahrzeugtyp_cabrio_aktiv.png?inline'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useMultiSelectCheckboxState } from '../../../../common/common/Navigators/MultiSelectNavigator/useMultiSelectCheckboxState'
import { SearchCallback } from '../../../../common/common/SearchCallback'
import { Navigator } from '@bbx/search-journey/common/Navigators'
import { buildAdditionalParamsFromCheckboxesHardcodedStatic } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { theme } from '@wh-components/core/theme'

export const CarTypeNavigator: FunctionComponent<{ navigator: Navigator; onSearch: SearchCallback }> = ({ navigator, onSearch }) => {
    const { checkboxState, handleChange } = useMultiSelectCheckboxState(navigator)
    return (
        <CarTypeNavigatorInner
            checkboxState={checkboxState}
            id={navigator.id}
            handleChange={async (id, checked) => {
                const newState = handleChange(id, checked)
                const additionalParams = buildAdditionalParamsFromCheckboxesHardcodedStatic(navigator, newState)
                await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
            }}
        />
    )
}

const carTypes = [
    { id: '6', typeName: 'Limousine', inactiveIconUrl: carTypeLimousineInactive, activeIconUrl: carTypeLimousineActive },
    { id: '10', typeName: 'SUV Geländewagen', inactiveIconUrl: carTypeSuvInactive, activeIconUrl: carTypeSuvActive },
    { id: '5', typeName: 'Kombi Family Van', inactiveIconUrl: carTypeKombiInactive, activeIconUrl: carTypeKombiActive },
    { id: '4', typeName: 'Klein- und Kompaktwagen', inactiveIconUrl: carTypeKleinwagenInactive, activeIconUrl: carTypeKleinwagenActive },
    { id: '9', typeName: 'Sportwagen Coupé', inactiveIconUrl: carTypeSportwagenInactive, activeIconUrl: carTypeSportwagenActive },
    { id: '2', typeName: 'Cabrio Roadster', inactiveIconUrl: carTypeCabrioInactive, activeIconUrl: carTypeCabrioActive },
    { id: '12', typeName: 'Kleinbus', inactiveIconUrl: carTypeKleinbusInactive, activeIconUrl: carTypeKleinbusActive },
]

const CarTypeNavigatorInner: FunctionComponent<{
    checkboxState: Record<string, boolean>
    handleChange: (id: string, checked: boolean) => void
    id: string
}> = (props) => (
    <Box
        display={{ phone: 'none', tablet: 'flex' }}
        width="100%"
        marginBottom="m"
        testId={`navigator-${props.id}`}
        css={css`
            > * {
                flex: 1 1 100%;
            }

            > *:not(:last-child) {
                margin-right: ${(p) => p.theme.space.xs}px;
            }
        `}
    >
        {carTypes.map((c) => {
            const selected = props.checkboxState[c.id] || false
            return (
                <Car
                    key={c.id}
                    typeName={c.typeName}
                    selected={selected}
                    inactiveIconUrl={c.inactiveIconUrl}
                    activeIconUrl={c.activeIconUrl}
                    onClick={() => props.handleChange(c.id, !selected)}
                />
            )
        })}
    </Box>
)

const Car: FunctionComponent<{
    typeName: string
    selected: boolean
    inactiveIconUrl: string
    activeIconUrl: string
    onClick: () => void
}> = ({ typeName, selected, inactiveIconUrl, activeIconUrl, onClick }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const searchBoxForegroundTextColor = advertisingState.pageModifications.foregroundColors?.['motor-search-box-text']
    const hoverBackgroundColor = advertisingState.pageModifications.backgroundColors?.['motor-car-type-image-hover']

    return (
        <CarButtonContainer
            data-testid={`navigator-type-${typeName}-button`}
            onClick={onClick}
            role="switch"
            aria-checked={selected}
            hoverBackgroundColor={hoverBackgroundColor ?? theme.colors.palette.polarbear}
        >
            <img src={selected ? activeIconUrl : inactiveIconUrl} width="74" height="50" alt="" />
            <Text maxWidth="100%" fontSize={10} textAlign="center" color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}>
                {typeName}
            </Text>
        </CarButtonContainer>
    )
}

const CarButtonContainer = styled.button.withConfig({ shouldForwardProp: (prop) => isPropValid(prop) })<{ hoverBackgroundColor: string }>`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    outline: none;
    user-select: none;
    cursor: pointer;
    border-radius: ${(p) => p.theme.borderRadius}px;
    transition: box-shadow 0.2s;

    &:hover {
        background: ${(p) => p.hoverBackgroundColor};
    }

    &:focus {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.palette.primary.main};
    }
`
